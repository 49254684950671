import React, { useEffect, useState } from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import Database from "../_utils/Database";
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel";

const QrRewards = () => {
    const [rewardsMessage, setRewardsMessage] = useState();
    const [rewardsList, setRewardsList] = useState();

    useEffect(() => {
        setRewardsMessage('Please see your treats below. We will send you more details via email. Don\'t forget to check your spam folder too! The fun doesn\'t need to stop, keep scanning to unlock more treats.');
        setRewardsList(Database.getRewardsList());
    }, []);


    return (
        <Layout title="Rewards">
            <div className="rewards-carousel">
                <MainTitle>Here's <br/> your gift</MainTitle>

                {rewardsMessage && <PageSummary>{rewardsMessage}</PageSummary>}

                {!!rewardsList?.length &&
                    rewardsList?.length === 1
                    ? rewardsList.map((item, index) => (
                        <div key={index} className="reward-carousel single">
                            <img src={item.reward_image_url} alt="" />
                            <div className="title">
                                <p>{item.reward_title}</p>
                            </div>
                        </div>
                    ))
                    : rewardsList?.length >= 2 && (
                        <RewardsCarousel rewardsList={rewardsList} />
                    )}
            </div>
        </Layout>
    );
};

export default QrRewards;
